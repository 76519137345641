import React, {useState} from 'react';
import MainForm from "./MainForm";
import Result from "./Result";
import PageLayout from "./PageLayout";


const App: React.FC = () => {
    const [result, setResult] = useState("");

    return (
        <PageLayout>
            <MainForm setResult={setResult} />
            <Result value={result} />
        </PageLayout>
    );
};

export default App;
